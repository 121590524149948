// import "../styles/global.scss";
import 'carbon-components/scss/globals/scss/styles.scss';
// import './app.scss'
import "./index.scss";
import { SessionProvider } from "next-auth/react"

export default function App({ Component, pageProps }) {
    return (
        <SessionProvider session={pageProps.session}>
            <Component {...pageProps} />
        </SessionProvider>
    )
}